<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-files"> </i> 档案管理 | 档案空间</h4>
          </div>
          <div class="col-sm-6">
            <span style="font-size: 20px; font-weight: bold;line-height: 2.5em">
              {{ archive.NAME }}
              <span v-if="cp_id!=''">/ {{cp_name}}</span>
            </span>
          </div>
          <div class="col-sm-2">
            <el-button size="small" icon="el-icon-back" style="float: right;margin-top: 10px;"
              @click="backPage">返回</el-button>
          </div>
        </div>
      </div>
      <div class="content">
        <el-slider v-model="widthR" :show-tooltip="false" @change="widthChange">
        </el-slider>
        <el-container style="height: 900px; background: #fff; border: 1px solid #fff; border-radius: 15px">

          <el-aside :width="widthR+'%'" style="border-right: solid 1px #ccc;padding: 10px">
            <el-tree :data="ls_dir" :props="{label:'NodeName',children:'Children'}" node-key="NodeId"
              :default-expand-all="true" @node-click="loadFile" highlight-current :expand-on-click-node="false">
              <el-row slot-scope="{node,data}" style="width: 100%;">
                <el-col :span="22" style="font-size: 15px; ">{{data.NodeName}}</el-col>
                <el-col :span="2" style="text-align: right">
                  <el-popover placement="right" trigger="hover" v-if="data.Power.Folder_Add||data.Power.File_Del">
                    <i slot="reference" class="el-icon-setting" />
                    <div>
                      <el-button type="text" icon="el-icon-plus" @click="dirEdit(data,1)"
                        v-show="data.Power.Folder_Add">新增</el-button>
                      <el-button type="text" icon="el-icon-edit" @click="dirEdit(data,2)"
                        v-show="data.Power.Folder_Add&&data.NodeId>0">修改</el-button>
                      <el-button type="text" icon="el-icon-delete" @click="dirDel(data)"
                        v-show="data.Power.Folder_Del&&data.NodeId>0">删除</el-button>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </el-tree>
          </el-aside>
          <el-main>
            <el-row :gutter="10">
              <el-col :span="4" v-if="archive.STA!=9">
                <div style="width: 160px; height: 180px; text-align: center; cursor:pointer" @click="upload">
                  <div>
                    <el-image :src="require('@/assets/ext/upload.png')" fit="fit" style="width: 90px" />
                  </div>
                  <div>上传</div>
                </div>
              </el-col>
              <el-col :span="4" v-for="it in ls_file" :key="it.ID">
                <el-popover placement="top" trigger="hover">
                  <div style="width: 160px; height: 180px; text-align: center" slot="reference">
                    <div>
                      <el-image :src="require('@/assets/ext/'+getExtImg(it.NAME))" fit="fit" style="width: 90px" />
                    </div>
                    <div>{{it.NAME}}</div>
                  </div>
                  <!-- :href="'/api/school/doc/content/download/'+it.GUID" -->
                  <div style="text-align: center;color: #409EFF" v-if="power.File_Del||power.File_Down">
                    <el-link type="primary" @click="downUrl(it)" v-if="power.File_Down">
                      <i class="el-icon-download" />
                      下载
                    </el-link>
                    <el-link type="primary" @click.native="fileDel(it.ID)" style="margin-left: 20px; "
                      v-if="power.File_Del">
                      <i class="el-icon-delete" />
                      删除
                    </el-link>
                  </div>
                </el-popover>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </div>
    </div>
    <div>
      <Edit ref="dir_edit" @loadDir2="loadDir2" />
    </div>
    <div>
      <Upload ref="upload" />
    </div>
  </div>
</template>
<script>
  import Edit from "./edit";
  import Upload from "./upload"
  import jsFileDownload from 'js-file-download'
  export default {
    name: "index",
    components: { Edit, Upload },
    data() {
      return {
        archive: this.$route.params,
        ls_file: [],
        ls_dir: [],
        expandedKeys: [],
        cp_id: "",
        cp_name: "",
        power: {
          View: false
        },
        widthR: 30
      }
    },
    created() {
      if (this.$route.params.ID === undefined)
        this.$router.push({ path: '/doc/archive' })
      else {
        this.loadDir2();
        // this.loadFile();
      }
    },
    methods: {
      loadDir() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/DOC/ContentApi/GetTree",
          data: {
            AID: self.archive.ID,
            TYPE: 1,
            PARENT_ID: 0
          },
          completed: function (its) {
            let t = { ID: "", NAME: '根目录', LEAF: false, CHILDREN: its.DATA, AID: self.archive.ID };
            self.ls_dir = [t];
          }
        })
      },
      loadDir2() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/DOC/ContentApi/GetTableTree",
          data: {
            archiveId: self.archive.ID
          },
          completed: function (its) {
            self.ls_dir = its.DATA.TreeNodes;
            // this.expandedKeys = []
            // self.getAllNodeKeys(self.ls_dir);
            self.loadFile(self.ls_dir[0]);
          }
        })
      },
      getAllNodeKeys(treeDatas) {
        treeDatas.forEach(item => {
          this.expandedKeys.push(item.NodeId);
          if (item.Children.length > 0) {
            this.getAllNodeKeys(item.Children);
          }
        });
      },
      dirEdit(dt, op) {
        this.$refs.dir_edit.init(dt, op, this.archive.ID);
      },
      dirDel(dt) {
        let self = this;
        if (dt.NodeId == "" || dt.NodeId == 0) {
          this.whale.toast.err("不能删除根目录");
          return
        }
        this.whale.delConfirm(() => {
          this.whale.remote.getResult({
            url: "/api/School/DOC/ContentApi/Delete",
            data: { ID: dt.NodeId },
            completed: function () {
              self.loadDir2()
              if (self.cp_id == dt.NodeId) {
                self.cp_id = "";
                self.loadFile(dt.Power);
              }
            }
          })
        });
      },
      loadFile(data) {
        let self = this;
        if (data !== undefined) {
          this.cp_id = data.NodeId
          this.cp_name = data.NodeName
          this.power = data.Power
        }
        self.ls_file = []
        if (self.power.View) {
          this.whale.remote.getCollection({
            url: "/api/School/DOC/ContentApi/GetList",
            data: {
              AID: self.archive.ID,
              TYPE: 2,
              PARENT_ID: this.cp_id
            },
            completed: function (its) {
              self.ls_file = its;
            }
          })
        }
      },
      getExtImg(fn) {
        let spl = fn.split('.');
        let n = spl.length
        if (n >= 2) {
          let ext = spl[n - 1];
          if (ext == "doc" || ext == "docx") return "word.png";
          else if (ext == "xls" || ext == "xlsx") return "excel.png";
          else if (ext == "ppt" || ext == "pptx") return "ppt.png";
          else if (ext == "pdf") return "pdf.png";
          else if (ext == "wps") return "wps.png";
          else if (ext == "jpg" || ext == "jpeg" || ext == "png" || ext == "gif" || ext == "bmp") return "pic.png";
          else if (ext == "txt") return "txt.png";
          else return "unknow.png"
        } else
          return "unknow.png"
      },
      upload() {
        this.$refs.upload.init(this.archive.ID, this.cp_id);
      },
      fileDel(id) {
        let self = this;
        this.whale.delConfirm(() => {
          this.whale.remote.getResult({
            url: "/api/School/DOC/ContentApi/Delete",
            data: {
              ID: id
            },
            completed: function () {
              // self.loadFile()
              self.ls_file = self.ls_file.filter(x => x.ID != id)
            }
          })
        })
      },
      backPage() {
        this.$router.push({ name: 'doc-archive' })
        // this.$router.go(-1);
      },
      downUrl(item) {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/DOC/Content/DownLoad2/" + item.GUID,
          completed: function (res) {
            self.whale.downLoad2(res, item.NAME)
          }
        })
      },
      widthChange(val) {
        if (val < 30)
          this.widthR = 30
        else if (val > 70)
          this.widthR = 70
      }
    }
  }
</script>