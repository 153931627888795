<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">上传文件</span>
      <div class="row">
        <div class="col-md-12">
          <el-upload drag action="#" :http-request="upload" multiple :on-success="onSuccess" :data="model">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">每个文件不超过5MB</div>
          </el-upload>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="onClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false,
        model: {},
      }
    },
    methods: {
      init(aid, pid) {
        this.show = true;
        this.model = { PARENT_ID: pid, AID: aid };
      },
      upload(file) {
        let self = this;
        const formData = new FormData();
        formData.append("file", file.file);
        formData.append("PARENT_ID", this.model.PARENT_ID);
        formData.append("AID", this.model.AID);
        this.whale.remote.import({
          url: 'api/School/DOC/Content/UpLoad',
          data: formData,
          completed: function (res) {
            if (res.SUCCESS)
              self.whale.toast.info("上传成功")
            else
              self.whale.toast.err(res.MESSAGE)
          }
        })
      },
      onSuccess(resp) {
        //resp,file,fileList
        console.log(resp)
        let self = this;
        if (!resp.SUCCESS) {
          self.whale.toast.err(resp.MESSAGE)
        }
      },
      onClose() {
        this.show = false;
        this.$parent.loadFile();
      }
    }
  }
</script>