<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">增加/修改 目录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>目录名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        show: false,
        model: {},
        refd: {},
        op: 0
      }
    },
    methods: {
      init(data, op, aid) {
        this.show = true;
        this.op = op;
        //1:add 2:edit
        if (op == 1) {
          this.model = { PARENT_ID: data.NodeId, TYPE: 1, AID: aid };
        } else {
          this.model = { ID: data.NodeId, NAME: data.NodeName, TYPE: 2, AID: aid };
          // this.model = JSON.parse((JSON.stringify(data)));
        }
        this.refd = data;
      },
      submit() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/DOC/ContentApi/Save",
          data: self.model,
          completed: function () {
            // if (self.op == 1) {
            //   if (!self.refd.Children) self.$set(self.refd, 'Children', []);
            //   self.refd.Children.push(it.DATA)
            // } else {
            //   self.refd.NAME = self.model.NAME;
            // }
            self.$emit('loadDir2');
            self.show = false;
          }
        })
      }
    }
  }
</script>